<div class="filter-container">
  <div class="block-title">
    <div class="h2">Filter</div>
    <button type="button" class="close" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <rect width="28" height="28" rx="6" fill="none" />
        <g transform="translate(4 4)">
          <rect width="20" height="20" fill="none" />
          <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                transform="translate(-0.005 0.005)" fill="#757575" />
        </g>
      </svg>
    </button>
  </div>
  <form [formGroup]="filterFormGroup">
    <div class="filter-detail">
      <div class="table-selection-block">

        <div class="row">
          <div class="col-md-6">
            <div class="select-options">
              <label for="">Task type</label>
              <div class="select-block">
                <ng-select [searchable]="true" [clearable]="true" placeholder="Task type" (change)="getReasonCodes(filterFormGroup.value.taskTypeId, false)"
                           name="branch" class="form-control" formControlName="taskTypeId">
                  <ng-option *ngFor="let task of taskTypeList" [value]="task.taskTypeId">{{task.description}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-options">
              <label for="">Status</label>
              <!-- <div class="select-block">
                <ng-select [searchable]="true" [clearable]="true" placeholder="Status"
                           name="branch" class="form-control" formControlName="statusId">
                  <ng-option *ngFor="let status of statusTypeList" [value]="status.id">{{status.status}}</ng-option>
                </ng-select>
              </div> -->

              <div class="select-block two-column-grid multi-bor-0 width-auto">
                <ng-multiselect-dropdown name="status" class="form-control"
                                         [placeholder]="'Status'" [settings]="dropdownSettingStatus" [data]="statusTypeList" formControlName="statusId">
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="select-options">
              <label for="">Reason</label>
              <div class="select-block">
                <ng-select [searchable]="true" [clearable]="true" placeholder="Reason"
                           name="branch" class="form-control" formControlName="reasonId">
                  <ng-option *ngFor="let reason of reasonList" [value]="reason.taskReasonCodeId">{{reason.description}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="select-options">
              <label for="">Status</label>
              <div class="select-block">
                <ng-select [searchable]="true" [clearable]="true" placeholder="Status"
                           name="branch" class="form-control min-height" formControlName="statusId">
                  <ng-option *ngFor="let status of statusTypeList" [value]="status.id">{{status.status}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div> -->

          <div class="col-md-12" *ngIf="!isContactUser">
            <div class="select-options">
              <label class="input-label" for="assign">Assign to</label>
              <div class="select-block multi-bor-0 width-auto">
                <ng-multiselect-dropdown name="assignByIds" class="form-control two-column-dd"
                                         [placeholder]="'Agent'" [settings]="dropdownSettingAssignTo" [data]="assignToList" formControlName="assignByIds">
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="!isContactUser">
            <div class="select-options">
              <label class="input-label" for="createdBy">Created By</label>
              <div class="select-block multi-bor-0 width-auto">
                <ng-multiselect-dropdown name="createdByIds" class="form-control two-column-dd"
                                         [placeholder]="'Created by'" [settings]="dropdownSettingCreatedBy" [data]="createdByList" formControlName="createdByIds">
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-options">
              <label class="input-label" for="dueDate">Due date</label>
              <div class="select-block">
                <input class="form-control pointer-none" placeholder="mm/dd/yyyy" id="dueDate"
                       name="dueDate" ngbDatepicker #d="ngbDatepicker" formControlName="dueDate" />
                <div class="new-tasks-model-date">
                  <span class="close-icon date-close" (click)="clearDate()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                         viewBox="0 0 24 24">
                      <rect _ngcontent-wvn-c189="" width="20" height="20" rx="6"
                            fill="none"></rect>
                      <g _ngcontent-wvn-c189="" transform="translate(4 4)">
                        <rect _ngcontent-wvn-c189="" width="16" height="16" fill="none">
                        </rect>
                        <path _ngcontent-wvn-c189=""
                              d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                              transform="translate(-0.005 0.005)" fill="#9e9e9e"></path>
                      </g>
                    </svg>
                  </span>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                            type="button"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-options priority-drpdown">
              <label class="input-label" for="priority">Priority</label>
              <div class="select-block">
                <ng-select class="form-control" tabindex="0" [searchable]="true"
                           [clearable]="true" placeholder="Priority" formControlName="priorityId">
                  <ng-option *ngFor="let priorityList of priorityTypeList" [value]="priorityList.id">
                    <span class="priority type-{{priorityList.id}}">{{priorityList.status}}</span>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="filter-button-group">
        <div class="right-btn">
          <button class="border-btn" style="margin-right: 10px;" (click)="clearFilter()">Clear All</button>
          <button class="btn dark-btn" (click)="applyFilter()">Apply</button>
        </div>
      </div>
    </div>
  </form>
</div>



