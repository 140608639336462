import { Component, OnInit, SimpleChange } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'models';
import { AccountService, ApiClientService } from 'api-services';
import { SharedService } from 'services';
import { firstValueFrom, Subscription } from 'rxjs';
import { CommonFunc } from '../../common/common-func/common-func';
import { ValidatorHelper } from '../../custom-validators/validators.helper';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  errorMessages = [];
  loginForm!: FormGroup;
  subscription: Subscription[] = [];
  renderUI = true;
  public userLogin: Login = {} as Login;
  isInputVal: boolean = false;
  isPasswordVal: boolean = false;
  constructor(private router: Router, private fb: FormBuilder, private apiClient: ApiClientService,
    private accountService: AccountService, private sharedService: SharedService,
    public meta: Meta, public title: Title,
    private activateRoute: ActivatedRoute) {
    this.redirectToDomain();
  }

  isShow = true;
  scheduleDemoURL = environment.scheduleDemoURL;
  ngOnInit(): void {
    // Title and Meta Description
    this.meta.addTag({ name: 'Description', content: 'Discover our revolutionary SaaS product that simplifies producer license requests, appointments, terminations etc. ensuring 100% compliance with insurance regulations for your company.' }); 
    this.title.setTitle('Login to the Agenzee App');
    //
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, ValidatorHelper.emailPattern]],
      password: ['', [Validators.required]],
      platformId: 1
    });
  }

  redirectToDomain() {
    this.activateRoute.queryParams.subscribe(params => {
      if (params['id'] && params['id'] != '') {
        if (params['id']) {
          this.renderUI = false;
          this.subscription.push(this.accountService.getUser(CryptoJS.AES.decrypt(decodeURIComponent(params['id']), environment.encryptdecrypt).toString(CryptoJS.enc.Utf8)).subscribe(
            (response: any) => {
              this.errorMessages = [];
              this.accountService.setToken(response, response.token);
              this.tokenValue(response);

            },
            (error: any) => {
              this.errorMessages = [];
              this.errorMessages.push("invalid id.")
            }
          ))
        }
      }
    });

  }

  tokenValue(response: any) {
    if (response.token != null) {
      if (response.role.trim().toLowerCase() == "Contact".toLowerCase()) {
        localStorage.setItem("activeContactUserId", response.id.toString());
        localStorage.setItem("isArchived", JSON.stringify(false));
        this.router.navigate(['/contact-dashboard']);
      } else if (response.role.trim().toLowerCase() == "Admin".toLowerCase()) {
        localStorage.setItem("activeContactUserId", response.id.toString());
        this.router.navigate(['/dashboard']);
      } else if (response.role.trim().toLowerCase() == "Guest".toLowerCase()) {
        localStorage.setItem("guestUserId", response.id.toString());
        this.router.navigate(['/packages-carrier']);
      }
      else if (response.profileStatus >= 5) {
        localStorage["profileStatus"] = 5;

        this.router.navigate(['/dashboard']);

      }
      else if (response.profileStatus == 0) {
        localStorage["profileStatus"] = 0;
        this.router.navigate(['/registration/product-plan']);
      }
      else if (response.profileStatus == 1) {
        localStorage["profileStatus"] = 1;
        this.router.navigate(['/registration/domain']);
      }
      else if (response.profileStatus == 2) {
        if (response.tenantTypeId == 3) {
          localStorage["profileStatus"] = 3;
          this.router.navigate(['/registration/npn']);
        }
        else {
          localStorage["profileStatus"] = 2;
          this.router.navigate(['/registration/niprcred']);
        }
      }
      else if (response.profileStatus == 3) {
        localStorage["profileStatus"] = 3;
        this.router.navigate(['/registration/npn']);
      }
      else if (response.profileStatus == 4) {
        localStorage["profileStatus"] = 4;
        this.router.navigate(['/registration/add-contact']);
      }

      localStorage.setItem("userRolePermissionIds", response.userRolePermissionIds.toString());
      localStorage.setItem("downliningTypeId", response.downliningTypeId);
    } else {
      this.errorMessages = [];
      this.errorMessages.push("Email or password incorrect.")
    }
  }
  //private async refreshingTokens(token: string | null): Promise<boolean> {
  //  const refreshToken: string | null = localStorage.getItem("refreshToken");

  //  if (!token || !refreshToken) {
  //    localStorage.clear();
  //    return false;
  //  }

  //  const tokenModel = JSON.stringify({ token: token, refreshToken: refreshToken, tokenGuId: localStorage.getItem("tokenGuId").toString(), userId: localStorage.getItem("userId").toString() });

  //  let isRefreshSuccess: boolean;
  //  try {
  //    const response = await firstValueFrom(this.apiClient.post(`api/User/refresh-token`, tokenModel, null, true));
  //    const newToken = (<any>response).accessToken;
  //    const newRefreshToken = (<any>response).refreshToken;
  //    const propToken = (<any>response).propToken;

  //    this.accountService.setToken(propToken, newToken);
  //    isRefreshSuccess = true;

  //  }
  //  catch (ex) {
  //    isRefreshSuccess = false;
  //  }
  //  return isRefreshSuccess;
  //}

  ngOnChanges(changes: SimpleChange) {
    // check input value is null or not
    var get_input_form = this.loginForm.value;
    if (get_input_form.email != '') {
      this.isInputVal = true;
      //  console.log(this.isInputVal);
    }
    else if (get_input_form.password != '') {
      this.isPasswordVal = true;

    }
  }
  onSubmit(): void {
    this.Submit();

  }
  Submit(): void {
    this.errorMessages = [];
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.subscription.push(this.accountService.loginUser(this.loginForm.value).subscribe(
        (response: any) => {
          this.errorMessages = [];

          this.accountService.setToken(response, response.token);
          if (environment.production == true && response.domain && !window.location.href.includes(response.domain + '.agenzee.com')) {
            //window.location.href = 'http://localhost:4200/login?id=' + encodeURIComponent(CryptoJS.AES.encrypt(response.id.toString(), environment.encryptdecrypt));
            localStorage.clear();
            window.location.href = 'https://' + response.domain + '.agenzee.com/login?id=' + encodeURIComponent(CryptoJS.AES.encrypt(response.id.toString(), environment.encryptdecrypt));
          } else
            this.tokenValue(response);
          //  if (response.role.trim().toLowerCase() == "Contact".toLowerCase()) {
          //    localStorage.setItem("activeContactUserId", response.id.toString());
          //    this.router.navigate(['/contact-dashboard']);
          //  } else if (response.role.trim().toLowerCase() == "Admin".toLowerCase()) {
          //    localStorage.setItem("activeContactUserId", response.id.toString());
          //    this.router.navigate(['/dashboard']);
          //    //window.location.href = 'https://' + response.domain + '.agenzee.com/dashboard';
          //  }
          //  else if (response.profileStatus >= 5) {
          //    localStorage["profileStatus"] = 5;

          //    this.router.navigate(['/dashboard']);
          //    //window.location.href = 'https://' + response.domain + '.agenzee.com/dashboard';

          //  }
          //  else if (response.profileStatus == 0) {
          //    localStorage["profileStatus"] = 0;
          //    this.router.navigate(['/product-plan']);
          //  }
          //  else if (response.profileStatus == 1) {
          //    localStorage["profileStatus"] = 1;
          //    this.router.navigate(['/domain']);
          //  }
          //  else if (response.profileStatus == 2) {
          //    localStorage["profileStatus"] = 2;
          //    this.router.navigate(['/niprcred']);
          //  }
          //  else if (response.profileStatus == 3) {
          //    localStorage["profileStatus"] = 3;
          //    this.router.navigate(['/npn']);
          //  }
          //  else if (response.profileStatus == 4) {
          //    localStorage["profileStatus"] = 4;
          //    this.router.navigate(['/add-contact']);
          //  }
          //} else {
          //  this.errorMessages = [];
          //  this.errorMessages.push("Email or password incorrect.....!!")
          //}
        },
        (error: any) => {
          this.errorMessages = [];
          this.errorMessages.push("Email or password incorrect.")
        }
      ))
    }
  }

  showPassword(id) {
    this.isShow = !this.isShow;
    CommonFunc.showPassword(id);
  }


}
