import { Injectable } from '@angular/core';
import { ApiClientService } from './common/api-client.service';
import { CarrierContactAssoc, CarrierDomain, InsertArchiveCarrierDomain, InsertCarrierFieldConfiguration } from '../models/packages/carrierContactAssoc.model';
import { ContactModel } from '../models/packages/contact.model';
import { ActivityHistory, Package, PackageAttachment, PackageDetailNote, PackageNote, SearchPackage } from '../models/packages/package.model';
import { SearchProducerRequest } from '../models/packages/searchProducerRequest.model';
import { VerifyGuestUserRequest } from '../models/packages/verifyGuestUserRequest.model';
import { CompletePackageRequest } from '../models/packages/completePackageRequest.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';
import { RoleService } from 'services/role.service';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  
  constructor(
    private apiClient: ApiClientService, private http: HttpClient, private roleService: RoleService) { }

  getCarrierContactAssoc(searchName: string) {
    return this.apiClient.get(`api/package/getCarrierContactAssoc/${searchName}`, null, null, true);
  }

  insertCarrierContact(contact: ContactModel) {
    return this.apiClient.post(`api/package/insertCarrierContact`, contact, null, true);
  }

  updateCarrierContact(contact: ContactModel) {
    return this.apiClient.post(`api/package/updateCarrierContact`, contact, null, true);
  }

  makeDefaultContact(contact: ContactModel) {
    return this.apiClient.post(`api/package/makeDefaultContact`, contact, null, true);
  }

  deleteCarrierContact(carrierContactAssocId: number) {
    return this.apiClient.post(`api/package/deleteCarrierContact/${carrierContactAssocId}`, null, null, true);
  }

  getActivePackages(searchPackage: SearchPackage) {
    // if (searchName != null) {
    //   return this.apiClient.get(`api/package/getActivePackages/${searchName}`, null, null, true);
    // }
    // else {
      return this.apiClient.post(`api/package/getActivePackages`, searchPackage, null, true);
    //}
  }

  getArchivePackages(searchPackage: SearchPackage) {
    // if (searchName != null) {
    //   return this.apiClient.get(`api/package/getArchivePackages/${searchName}`, null, null, true);
    // }
    // else {
      return this.apiClient.post(`api/package/getArchivePackages`, searchPackage, null, true);
    //}
  }

  getPackageDetail(packageId: number) {    
    return this.apiClient.get(`api/package/getPackageDetail/${packageId}`, null, null, true);

  }

  insertPackage(packageObj: Package) {
    return this.apiClient.post(`api/package/insertPackage`, packageObj, null, true);
  }

  getPackageNotes(packageDetailId: number, guestUser: boolean = false) {      
    if(guestUser == false)
      return this.apiClient.get(`api/package/getPackageNotes/${packageDetailId}`, null, null, true);
    else 
      return this.apiClient.get(`api/packageguestuser/getPackageNotesForCarrier/${packageDetailId}`, null, null, true);
  }

  insertPackageNote(packageNote: PackageDetailNote) {
    if(packageNote.GuestUser == false)
      return this.apiClient.post(`api/package/insertPackageNote`, packageNote, null, true);
    else  
      return this.apiClient.post(`api/packageguestuser/insertPackageNoteForCarrier`, packageNote, null, true);
  }

  insertPackageAttachment(packageAttachment: PackageAttachment) {
    return this.apiClient.post(`api/package/insertPackageAttachment`, packageAttachment, null, true);
  }

  searchProducer(request: SearchProducerRequest) {
    return this.apiClient.post(`api/package/searchProducer`, request, null, true);
  }

  searchProducerAppointment(request: SearchProducerRequest) {
    return this.apiClient.post(`api/package/searchProducerAppointment`, request, null, true);
  }

  updatePackage(packageObj: Package, showLoading: boolean = true) {
    return this.apiClient.post(`api/package/updatePackage`, packageObj, null, showLoading);
  }

  archivePackage(packageId: number) {
    return this.apiClient.post(`api/package/archivePackage/${packageId}`, null, null, true);
  }

  deletePackage(packageId: number) {
    return this.apiClient.post(`api/package/deletePackage/${packageId}`, null, null, true);
  }

  getActiveLicenesList() {
    return this.apiClient.get(`api/package/getActiveLicenseList`, null, null, true);
  }

  getStates(carrierId: number = null) {
    if (carrierId != null) {
      return this.apiClient.get(`api/package/getStates/${carrierId}`, null, null, true);
    }
    else {
      return this.apiClient.get(`api/package/getStates`, null, null, true);
    }
  }

  getActiveLOAList(carrierId: number = null) {
    if (carrierId != null) {
      return this.apiClient.get(`api/package/getActiveLOAList/${carrierId}`, null, null, true);
    }
    else {
      return this.apiClient.get(`api/package/getActiveLOAList`, null, null, true);
    }
  }

  getCarrierPackageContactByCarrierId(carrierId: number, showLoading: boolean = true) {
    return this.apiClient.get(`api/package/getCarrierPackageContactByCarrierId/${carrierId}`, null, null, showLoading);
  }

  sendPackage(packageId: number) {
    return this.apiClient.post(`api/package/sendPackage/${packageId}`, null, null, true);
  }

  sendVerificationCode(activateUrl: string) {
    var contact = {} as ContactModel;
    contact.activateUrl = activateUrl;
    return this.apiClient.post(`api/packageguestuser/sendVerificationCode`, contact, null, true);
  }

  verifyGuestUser(verifyRequest: VerifyGuestUserRequest) {
    return this.apiClient.post(`api/packageguestuser/verifyGuestUser`, verifyRequest, null, true);
  }

  setPwd(setPwdObj: any) {
    return this.apiClient.post(`api/packageguestuser/setpassword`, setPwdObj, null, true);
  }

  getPackagesForCarrierUser(searchName: string = null) {
    if (searchName != null) {
      return this.apiClient.get(`api/packagecarrier/getPackages/${searchName}`, null, null, true);
    }
    else {
      return this.apiClient.get(`api/packagecarrier/getPackages`, null, null, true);
    }
  }

  getPackagesForGuestUser(searchName: string = null) {
    if (searchName != null) {
      return this.apiClient.get(`api/packageguestuser/getPackages/${searchName}`, null, null, true);
    }
    else {
      return this.apiClient.get(`api/packageguestuser/getPackages`, null, null, true);
    }
  }

  getPackageDetailForCarrierUser(packageId: number) {
    return this.apiClient.get(`api/packagecarrier/getPackageDetails/${packageId}`, null, null, true);

  }

  getPackageDetailForGuestUser(packageId: number) {
    return this.apiClient.get(`api/packageguestuser/getPackageDetails/${packageId}`, null, null, true);

  }

  getContactByEmail(contact: ContactModel) {
    return this.apiClient.post(`api/package/getContactByEmail`, contact, null, true);
  }

  getCarrierFieldConfiguration(carrierId: number) {
    return this.apiClient.get(`api/package/getCarrierFieldConfiguration/${carrierId}`, null, null, true);
  }

  insertCarrierFieldConfiguration(insertCarrierField: InsertCarrierFieldConfiguration) {
    return this.apiClient.post(`api/package/insertCarrierFieldConfiguration`, insertCarrierField, null, true);
  }

  getCarrierDomains(carrierId: number) {
    return this.apiClient.get(`api/package/getCarrierDomains/${carrierId}`, null, null, true);
  }

  insertCarrierDomain(insertCarrierDomain: InsertArchiveCarrierDomain) {
    return this.apiClient.post(`api/package/insertCarrierDomain`, insertCarrierDomain, null, true);
  }

  archiveCarrierDomain(archiveCarrierDomain: InsertArchiveCarrierDomain) {
    return this.apiClient.post(`api/package/archiveCarrierDomain`, archiveCarrierDomain, null, true);
  }

  completePackage(isGuestUser: boolean, formData: FormData) {
    const header = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );

    if (isGuestUser) {
      return this.apiClient.uploadFile(`api/packageguestuser/completePackage`, formData, header);
    }
    else {
      return this.apiClient.uploadFile(`api/packagecarrier/completePackage`, formData, header);
    }
  }

  inprogressPackage(isGuestUser: boolean, completedPackageRequest: CompletePackageRequest) {
    if (isGuestUser) {
      return this.apiClient.post(`api/packageguestuser/inprogressPackage`, completedPackageRequest, null, true);
    }
    else {
      return this.apiClient.post(`api/packagecarrier/inprogressPackage`, completedPackageRequest, null, true);
    }
  }

  getPackageDetailForReview(packageId: number) {
    return this.apiClient.get(`api/package/getPackageDetailForReview/${packageId}`, null, null, true);

  }

  updateRecipient(packageObj: Package) {
    return this.apiClient.post(`api/package/updateRecipient`, packageObj, null, true);
  }

  uploadAttachments(packageId, formData: FormData, packageDetailId = undefined, carrierUser: boolean = false, isGuestUser: boolean = false): Observable<any> {
    const headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );

    var controller = this.getControllerName(carrierUser, isGuestUser);
    var action = "uploadPackageAttachments";

    var requestUrl = environment.baseUrl + `api`;
    if (packageDetailId != undefined) {
      action = "uploadPackageDetailAttachments";
      requestUrl = `${requestUrl}/${controller}/${action}/${packageId}/${packageDetailId}`
    }
    else {
      requestUrl = `${requestUrl}/${controller}/${action}/${packageId}`
    }

    return this.http.post(requestUrl, formData, {
      headers: headers,
      reportProgress: true
    });
  }

  public downloadPackageFile(packageAttachmentId, carrierUser: boolean = false, isGuestUser: boolean = false) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
      })
    };

    var controller = this.getControllerName(carrierUser, isGuestUser);
    var requestUrl = environment.baseUrl + `api/${controller}/downloadPackageFile/${packageAttachmentId}`;

    return this.http.get(requestUrl, httpOptions)
      .pipe(map((response: any) => {
        let data = response;
        return data;
      }
      ));
  }

  public deletePackageAttachment(packageAttachmentId, carrierUser: boolean = false, isGuestUser: boolean = false) {
    var controller = this.getControllerName(carrierUser, isGuestUser);
    var requestUrl = `api/${controller}/deletePackageAttachment/${packageAttachmentId}`;
    return this.apiClient.delete(requestUrl, null, null, true);
  }

  getControllerName(carrierUser: boolean, isGuestUser: boolean) {
    var controller: string = "package";
    if (carrierUser) {
      if (isGuestUser) {
        controller = "packageguestuser";
      }
      else {
        controller = "packagecarrier";
      }
    }
    return controller;
  }

  public archivePackageAttachment(packageAttachmentId, carrierUser: boolean = false, isGuestUser: boolean = false) {
    var controller = this.getControllerName(carrierUser, isGuestUser);
    var requestUrl = `api/${controller}/archivePackageAttachment/${packageAttachmentId}`;

    return this.apiClient.post(requestUrl, null, null, true);
  }

  public unarchivePackageAttachment(packageAttachmentId, carrierUser: boolean = false, isGuestUser: boolean = false) {
    var controller = this.getControllerName(carrierUser, isGuestUser);
    var requestUrl = `api/${controller}/unarchivePackageAttachment/${packageAttachmentId}`;

    return this.apiClient.post(requestUrl, null, null, true);
  }

  getPackageAttachments(packageId: number, archive: boolean, packageDetailId: number = undefined, carrierUser: boolean = false, isGuestUser: boolean = false) {
    var controller = this.getControllerName(carrierUser, isGuestUser);
    var requestUrl = `api/${controller}/getPackageAttachments/${packageId}/${archive}`;

    if (packageDetailId != null) {
      requestUrl = `api/${controller}/getPackageAttachments/${packageId}/${archive}/${packageDetailId}`;
    }

    return this.apiClient.get(requestUrl, null, null, true);
  }

  getPackageActivityHistory(packageId: number, isChat: number | null, guestUser: boolean = false) {    
    let url;
    if(guestUser == false)
    {
      url = `api/package/getPackageActivityHistory/${packageId}/${this.roleService.isCarrierUser}`;
      if (isChat !== null) {
        url += `/${isChat}`;
      }
    } else{
      url = `api/packageguestuser/getPackageActivityHistoryForCarrier/${packageId}/true`;
      if (isChat !== null) {
        url += `/${isChat}`;
      }
    }
    return this.apiClient.get(`${url}`, null, null, true);
    // if(guestUser == false)
    //   return this.apiClient.get(`api/package/getPackageActivityHistory/${packageId}/${isChat}`, null, null, true);
    // else 
    //   return this.apiClient.get(`api/packageguestuser/getPackageActivityHistoryForCarrier/${packageId}/${isChat}`, null, null, true);
  }

  getPackageDetailActivityHistory(packageDetailId: number, isChat: number | null, guestUser: boolean = false) {    
    let url;
    if(guestUser == false)
    {
      url = `api/package/getPackageDetailActivityHistory/${packageDetailId}/${this.roleService.isCarrierUser}`;
      if (isChat !== null) {
        url += `/${isChat}`;
      }
    } else{
      url = `api/packageguestuser/getPackageDetailActivityHistoryForCarrier/${packageDetailId}/true`;
      if (isChat !== null) {
        url += `/${isChat}`;
      }
    }
    return this.apiClient.get(`${url}`, null, null, true);    
  }

  insertPackageActivityHistory(packageActivityHistory: ActivityHistory) {    
    if(packageActivityHistory.GuestUser == false)
      return this.apiClient.post(`api/package/insertPackageActivityHistory`, packageActivityHistory, null, true);
    else  
      return this.apiClient.post(`api/packageguestuser/insertPackageActivityHistoryForCarrier`, packageActivityHistory, null, true);
  }

  getContactByVerifyEmail(email:string) {    
    return this.apiClient.get(`api/packageguestuser/getContactByVerifyEmail/${email}`, null, null, true);
  }

  getActivationCodesByPackageId(packageId: number) {    
    return this.apiClient.get(`api/package/getActivationCodesByPackageId/${packageId}`, null, null, true);
  }

  deletePackageDetail(packageDetailId: number) {
    return this.apiClient.delete(`api/package/deletePackageDetail/${packageDetailId}`, null, null, true);
  }

  fileUpload(carrierId, formData: FormData): Observable<any> {
    const headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );

    return this.http.post(environment.baseUrl + `api/forms/fileUpload/${carrierId}`, formData, {
      headers: headers,
      reportProgress: true
    });
  }

  getFileUploadedFiles(carrierId: number) {
    return this.apiClient.get(`api/forms/getFileUploadedFiles/${carrierId}`, null, null, true);
  }

  getCarrierForms(carrierId: number) {
    return this.apiClient.get(`api/forms/getCarrierForms/${carrierId}`, null, null, true);
  }
}
