import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Filter, FilterCategory, FilterList, FilterRequest } from 'models/filter.model';
import { LicenseClass } from 'models/license-class.model';
import { SearchLicense } from 'models/search-license.model';
import { State } from 'models/state.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FilterService } from 'services/filter.service';
import { SharedService } from 'services/shared.service';
import { ValidatorHelper } from '../../custom-validators/validators.helper';
import { SearchAppointment } from 'models/search-appointment.modal';
import { TagList } from 'models/tag.model';
import { SearchProducerRequest } from '../../../models/packages/searchProducerRequest.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  isSavePopup: boolean = false;
  isDeletePopup: boolean = false;
  tenantTypeId = localStorage['tenantTypeId'];
  expiryDays = localStorage["expiryDays"];
  tenantId = Number(localStorage.getItem('tenantId'));
  userId = Number(localStorage.getItem('userId'));

  @Input() filterCategory: number;
  @Input() dropdownSettingState: IDropdownSettings;
  @Input() states: any[];
  @Input() dropdownSettingLicenseClass: IDropdownSettings;
  @Input() licenseClasses: any[];
  @Input() dropdownSettingTag: IDropdownSettings;
  @Input() tags: any[];
  @Input() branchList: any[];
  @Input() licenseStatusList: any[];
  @Input() expiryInData: any[];
  @Input() filterList: FilterList[] = [];
  @Input() departmentList: any[];
  @Input() tenantStatusList: any[];

  @Input() selectedStates: State[];
  @Input() selectedLicenseClasses: LicenseClass[];
  @Input() selectedTags: TagList[];
  @Input() selectedBranchId: number = null;
  @Input() selectedStatus: string = '';
  @Input() selectedExpireIn: number = null;
  @Input() selectedFilter: number;
  @Input() selectedFilterName: string = '';

  @Input() branchId: number = 0;
  @Input() departmentId: number = 0;
  @Input() tenantStatusId: number = 0;

  @Input() dropdownSettingAppointType: IDropdownSettings;
  //@Input() dropdownSettingTag: IDropdownSettings;
  @Input() appointmentTypeList: any;
  @Input() selectedAppointmentTypeList: any;

  @Output() filterCriteriaChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveFilter: EventEmitter<any> = new EventEmitter<any>();

  saveFilterForm: FormGroup;
  filter: Filter = {} as Filter;
  tempFilterList: FilterList[] = [];
  // filterList: FilterList[] = [];
  // selectedFilter: number;
  filterRequest: FilterRequest = {} as FilterRequest;
  searchLicenseModel: SearchLicense = {} as SearchLicense;
  searchAppointmentModel: SearchAppointment = {} as SearchAppointment;
  searchContactModel: any = {};
  loggedinUserRole = localStorage["userRole"]
  isLoad: boolean = false;
  status: string;

  isDisabled: boolean = false;

  filterCategoryProducer = FilterCategory.Producers;

  constructor(private sharedService: SharedService, private filterService: FilterService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.saveFilterForm = this.bindFormGroup();
    this.tempFilterList = this.filterList;

    // Producers
    if (this.filterCategory == 1) {
      this.status = 'All'
    }
    // Licenses
    else if (this.filterCategory == 3) {
      this.status = 'Appointed'
    }
    else if (this.filterCategory == 4) {
      this.status = null
    }

    // Appointments
    else {
      this.status = 'Active';
    }

    if (this.selectedFilter != undefined && this.filterList != undefined) {
      const filterData = this.filterList.find(obj => obj.filterId === this.selectedFilter);
      if (filterData && filterData.ownerUserId != this.userId) {
        this.isDisabled = true;
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  public onPopState(event: PopStateEvent) {
    this.modalService.dismissAll();
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
  }

  bindFormGroup(): FormGroup {
    return new FormGroup({
      filterName: new FormControl(null, [Validators.required]),
      default: new FormControl(this.loggedinUserRole != 'contact' ? false : true),
      public: new FormControl(false)
    });
  }

  clearFilter() {
    this.selectedFilter = null;
    if (this.filterCategory == 1) {
      this.departmentId = 0;
      this.branchId = 0;
      this.tenantStatusId = 0;
    }
    else {
      this.selectedStates = [];
      this.selectedLicenseClasses = [];
      this.selectedTags = [];
      this.selectedAppointmentTypeList = [];
      this.selectedBranchId = null;
      this.selectedStatus = this.status;
      this.selectedExpireIn = null;
      this.selectedFilterName = '';
      this.isDisabled = false;
    }
  }

  applyFilter() {
    if (arraysEqual(this.tempFilterList, this.filterList) == false)
      this.isLoad = true;

    if (this.filterCategory == 1) {
      const filterCriteria = {
        filter: this.selectedFilter,
        filterName: this.selectedFilterName,
        isLoad: this.isLoad,
        branchId: this.branchId == null ? 0 : this.branchId,
        departmentId: this.departmentId == null ? 0 : this.departmentId,
        tenantStatusId: this.tenantStatusId == null ? 0 : this.tenantStatusId
      };

      this.filterCriteriaChange.emit(filterCriteria);
    }
    else {
      const filterCriteria = {
        filter: this.selectedFilter,
        filterName: this.selectedFilterName,
        isLoad: this.isLoad,
        states: this.selectedStates,
        licenseClasses: this.selectedLicenseClasses,
        tags: this.selectedTags,
        appointmentTypes: this.selectedAppointmentTypeList,
        branchId: this.selectedBranchId,
        status: this.selectedStatus,
        expireIn: this.selectedExpireIn
      };

      this.filterCriteriaChange.emit(filterCriteria);
    }
  }

  openSaveFilterPopup() {
    this.isSavePopup = true;
    this.saveFilterForm = this.bindFormGroup();
  }

  closeSavePopup() {
    this.isSavePopup = false;
  }

  saveFilterChanges() {
    this.saveFilterForm.markAllAsTouched();
    if (this.saveFilterForm.valid) {

      this.isSavePopup = false;

      // Save filters to service        
      this.filter.TenantId = this.tenantId;
      this.filter.OwnerUserId = this.userId;
      this.filter.FilterCategoryId = this.filterCategory;
      this.filter.Name = this.saveFilterForm.value.filterName.trim();
      this.filter.Default = this.saveFilterForm.value.default;
      this.filter.Public = this.saveFilterForm.value.public;

      let criteria = null;

      if (this.filterCategory == 1) {
        criteria = this.setContactCriteria();
      }
      else if (this.filterCategory == 2 || this.filterCategory == 4) {
        criteria = this.setLicensesCriteria();
      }
      else if (this.filterCategory == 3) {
        criteria = this.setAppointmentCriteria();
      }

      this.filter.CreatedBy = this.userId;

      if ((this.filterCategory == 1) && ((criteria.status == null || criteria.status == undefined || criteria.status == 0)
        && (criteria.branchId == null || criteria.branchId == undefined || criteria.branchId == 0)
        && (criteria.departmentId == null || criteria.departmentId == undefined || criteria.departmentId == 0))) {
        this.sharedService.showToastMessage("No filter selected. Please ensure that at least one filter is selected before saving.");
      }
      else if ((this.filterCategory == 2) && (criteria.states == '') && (criteria.licenseClass == '') && (criteria.tags == '') && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.status == null || criteria.status == undefined || criteria.status == '') && (criteria.expireIn == null || criteria.status == undefined)) {
        this.sharedService.showToastMessage("No filter selected. Please ensure that at least one filter is selected before saving.");
      }
      else if ((this.filterCategory == 4) && (criteria.states == '') && (criteria.licenseClass == '') && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.status == null || criteria.status == undefined || criteria.status == '') && (criteria.expireIn == null || criteria.status == undefined)) {
        this.sharedService.showToastMessage("No filter selected. Please ensure that at least one filter is selected before saving.");
      }
      else if ((this.filterCategory == 3) && (criteria.states == '') && (criteria.appointmentTypes == '') && (criteria.tags == '') && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.status == null || criteria.status == undefined || criteria.status == '') && (criteria.expireIn == null || criteria.status == undefined)) {
        this.sharedService.showToastMessage("No filter selected. Please ensure that at least one filter is selected before saving.");
      }
      else {

        this.filter.Criteria = JSON.stringify(criteria);

        this.filterService.insertFilter(this.filter).subscribe(
          (response) => {
            // Handle successful response
            if (response && response > 0) {
              this.sharedService.showToastMessage("Filter saved successfully.");
              this.loadFilters("insert", response);
            }
          },
          (error) => {
            // Handle error           
            this.sharedService.showToastMessage("Unable to save the filter. Please try again.");
          }
        );

      }
    }
  }

  updateFilter() {
    // Update filters to service        
    this.filter.FilterId = this.selectedFilter;
    this.filter.TenantId = this.tenantId;

    let criteria = null;

    if (this.filterCategory == 1) {
      criteria = this.setContactCriteria();
    }
    else if (this.filterCategory == 2 || this.filterCategory == 4) {
      criteria = this.setLicensesCriteria();
    }
    else if (this.filterCategory == 3) {
      criteria = this.setAppointmentCriteria();
    }

    this.filter.UpdatedBy = this.userId;

    if ((this.filterCategory == 1) && ((criteria.status == null || criteria.status == undefined || criteria.status == '')
      && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.departmentId == null || criteria.departmentId == undefined))) {
      this.sharedService.showToastMessage("Please ensure that at least one filter is selected for Status, Branch, or Department.");
    }
    else
      if ((this.filterCategory == 2) && (criteria.states == '') && (criteria.licenseClass == '') && (criteria.tags == '') && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.status == null || criteria.status == undefined || criteria.status == '') && (criteria.expireIn == null || criteria.status == undefined)) {
        this.sharedService.showToastMessage("Please ensure that at least one filter is selected for State, License Class, Branch, Status, or Expires In.");
      }
      else if ((this.filterCategory == 4) && (criteria.states == '') && (criteria.licenseClass == '') && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.status == null || criteria.status == undefined || criteria.status == '') && (criteria.expireIn == null || criteria.status == undefined)) {
        this.sharedService.showToastMessage("Please ensure that at least one filter is selected for State, License Class, Branch, or Expires In.");
      }
      else if ((this.filterCategory == 3) && (criteria.states == '') && (criteria.appointmentTypes == '') && (criteria.tags == '') && (criteria.branchId == null || criteria.branchId == undefined) && (criteria.status == null || criteria.status == undefined || criteria.status == '') && (criteria.expireIn == null || criteria.status == undefined)) {
        this.sharedService.showToastMessage("Please ensure that at least one filter is selected for State, Appointment Type, Branch, Status, or Expires In.");
      }
      else {

        this.filter.Criteria = JSON.stringify(criteria);

        this.filterService.updateFilter(this.filter).subscribe(
          (response) => {
            // Handle successful response
            if (response && response > 0) {
              this.sharedService.showToastMessage("Filter updated successfully.");
            }
            else {
              this.sharedService.showToastMessage("Oops! Something went wrong. ");
            }
            this.loadFilters("update", this.filter.FilterId);
          },
          (error) => {
            // Handle error         
            this.sharedService.showToastMessage("Oops! Something went wrong. Please try again");
          }
        );

      }
  }

  setLicensesCriteria() {
    var stateIds = null;
    if (this.selectedStates && this.selectedStates.length > 0) {
      stateIds = this.selectedStates.map(function (val) {
        return val.stateId
      }).join(',');
    }

    var licenseClassCode = null;
    if (this.selectedLicenseClasses && this.selectedLicenseClasses.length > 0) {
      licenseClassCode = this.selectedLicenseClasses.map(function (val) {
        return val.licenseClassCode
      }).join(',');
    }

    var tagIds = null;
    if (this.selectedTags && this.selectedTags.length > 0) {
      tagIds = this.selectedTags.map(function (val) {
        return val.tagId
      }).join(',');
    }

    this.searchLicenseModel.states = stateIds;
    this.searchLicenseModel.licenseClass = licenseClassCode;
    this.searchLicenseModel.tags = tagIds;
    this.searchLicenseModel.branchId = this.selectedBranchId;
    this.searchLicenseModel.status = this.selectedStatus;
    this.searchLicenseModel.expireIn = this.selectedExpireIn;

    const criteria = { states: '', licenseClass: '', tags: '', branchId: null, status: '', expireIn: null };

    if (this.searchLicenseModel.states != null && this.searchLicenseModel.states != undefined && this.searchLicenseModel.states != '') {
      criteria.states = this.searchLicenseModel.states;
    }

    if (this.searchLicenseModel.licenseClass != null && this.searchLicenseModel.licenseClass != undefined && this.searchLicenseModel.licenseClass != '') {
      criteria.licenseClass = this.searchLicenseModel.licenseClass;
    }

    if (this.searchLicenseModel.tags != null && this.searchLicenseModel.tags != undefined && this.searchLicenseModel.tags != '') {
      criteria.tags = this.searchLicenseModel.tags;
    }

    if (this.searchLicenseModel.branchId != null && this.searchLicenseModel.branchId != undefined) {
      criteria.branchId = this.searchLicenseModel.branchId;
    }

    if (this.searchLicenseModel.status != null && this.searchLicenseModel.status != undefined && this.searchLicenseModel.status != '') {
      criteria.status = this.searchLicenseModel.status;
    }

    if (this.searchLicenseModel.expireIn != null && this.searchLicenseModel.expireIn != undefined) {
      criteria.expireIn = this.searchLicenseModel.expireIn;
    }

    return criteria;
  }

  setAppointmentCriteria() {
    var stateIds = null;
    if (this.selectedStates && this.selectedStates.length > 0) {
      stateIds = this.selectedStates.map(function (val) {
        return val.stateId
      }).join(',');
    }

    var AppointmentTypeId = null;
    if (this.selectedAppointmentTypeList && this.selectedAppointmentTypeList.length > 0) {
      AppointmentTypeId = this.selectedAppointmentTypeList.map(function (val) {
        return val.appointmentTypeId
      }).join(',');
    }

    var tagIds = null;
    if (this.selectedTags && this.selectedTags.length > 0) {
      tagIds = this.selectedTags.map(function (val) {
        return val.tagId
      }).join(',');
    }

    this.searchAppointmentModel.states = stateIds;
    this.searchAppointmentModel.appointmentTypes = AppointmentTypeId;
    this.searchAppointmentModel.tags = tagIds;
    this.searchAppointmentModel.branchId = this.selectedBranchId;
    this.searchAppointmentModel.status = this.selectedStatus;
    this.searchAppointmentModel.expireIn = this.selectedExpireIn;

    const criteria = { states: '', appointmentTypes: '', tags: '', branchId: null, status: '', expireIn: null };

    if (this.searchAppointmentModel.states != null && this.searchAppointmentModel.states != undefined && this.searchAppointmentModel.states != '') {
      criteria.states = this.searchAppointmentModel.states;
    }

    if (this.searchAppointmentModel.appointmentTypes != null && this.searchAppointmentModel.appointmentTypes != undefined && this.searchAppointmentModel.appointmentTypes != '') {
      criteria.appointmentTypes = this.searchAppointmentModel.appointmentTypes;
    }

    if (this.searchAppointmentModel.tags != null && this.searchAppointmentModel.tags != undefined && this.searchAppointmentModel.tags != '') {
      criteria.tags = this.searchAppointmentModel.tags;
    }

    if (this.searchAppointmentModel.branchId != null && this.searchAppointmentModel.branchId != undefined) {
      criteria.branchId = this.searchAppointmentModel.branchId;
    }

    if (this.searchAppointmentModel.status != null && this.searchAppointmentModel.status != undefined && this.searchAppointmentModel.status != '') {
      criteria.status = this.searchAppointmentModel.status;
    }

    if (this.searchAppointmentModel.expireIn != null && this.searchAppointmentModel.expireIn != undefined) {
      criteria.expireIn = this.searchAppointmentModel.expireIn;
    }

    return criteria;
  }

  setContactCriteria() {

    this.searchContactModel.branchId = this.branchId;
    this.searchContactModel.departmentId = this.departmentId;
    this.searchContactModel.tenantStatusId = this.tenantStatusId;

    const criteria = { branchId: 0, departmentId: 0, tenantStatusId: 0 };

    if (this.searchContactModel.branchId != null && this.searchContactModel.branchId != undefined && this.searchContactModel.branchId != 0) {
      criteria.branchId = this.searchContactModel.branchId;
    }

    if (this.searchContactModel.departmentId != null && this.searchContactModel.departmentId != undefined && this.searchContactModel.departmentId != 0) {
      criteria.departmentId = this.searchContactModel.departmentId;
    }

    if (this.searchContactModel.tenantStatusId != null && this.searchContactModel.tenantStatusId != undefined && this.searchContactModel.tenantStatusId != 0) {
      criteria.tenantStatusId = this.searchContactModel.tenantStatusId;
    }

    return criteria;
  }

  onCheckboxChange(event) {
    if (event.target.name == "default") {
      if (event.target.checked == true) {
        this.saveFilterForm.get("public").setValue(false);
        this.saveFilterForm.get('public').disable();
      }
      else {
        this.saveFilterForm.get("public").setValue(false);
        this.saveFilterForm.get('public').enable();
      }
    }
    else if (event.target.name == "public") {
      if (event.target.checked == true) {
        this.saveFilterForm.get("default").setValue(false);
        this.saveFilterForm.get('default').disable();
      }
      else {
        this.saveFilterForm.get("default").setValue(false);
        this.saveFilterForm.get('default').enable();
      }
    }
  }

  loadFilters(operation: string, currentFilter: number) {
    //Fetch the stored filter category filter details       
    this.filterService.getFilters(this.filterCategory, this.tenantId, this.userId).subscribe(
      (response) => {
        // Handle successful response
        this.filterList = response;

        if (this.filterList && this.filterList.length > 0) {
          var foundDefaultFilter = this.filterList.find(obj => obj.default === true);

          //If default filter not found and user selected public or any other filter and apply the changes at that time checking filter available or not
          if ((operation == "insert" || operation == "update") && currentFilter != null) {
            foundDefaultFilter = this.filterList.find(obj => obj.filterId === currentFilter);
          }
          else if (foundDefaultFilter == undefined && this.selectedFilter != null) {
            foundDefaultFilter = this.filterList.find(obj => obj.filterId === this.selectedFilter);
          }

          if (foundDefaultFilter) {
            this.selectedFilter = foundDefaultFilter.filterId;

            if (this.selectedFilter) {
              const filterData = this.filterList.find(obj => obj.filterId === this.selectedFilter);

              if (filterData && filterData.criteria != null) {
                this.selectedFilterName = filterData.name;

                if (filterData.ownerUserId != this.userId) {
                  this.isDisabled = true;
                }
                else {
                  this.isDisabled = false;
                }

                const criteria = JSON.parse(filterData.criteria);

                if (this.filterCategory == this.filterCategoryProducer) {

                  this.tenantStatusId = criteria.tenantStatusId;
                  this.branchId = criteria.branchId;
                  this.departmentId = criteria.departmentId;
                }
                else {
                  //Set selectedStates variable to bind state dropdown
                  if (criteria.states != null) {
                    const stateIdsArray: number[] = criteria.states.split(',').map(id => parseInt(id.trim(), 10));

                    this.selectedStates = [];
                    stateIdsArray.forEach(id => {
                      const foundState = this.states.find(state => state.stateId === id);
                      if (foundState) {
                        this.selectedStates.push(foundState);
                      }
                    });
                  }

                  //Set selectedLicenseClasses variable to bind license class dropdown
                  if (criteria.licenseClass != null) {
                    const licenseClassCodesArray: string[] = criteria.licenseClass.split(',');

                    this.selectedLicenseClasses = [];
                    licenseClassCodesArray.forEach(code => {
                      const foundlicenseClass = this.licenseClasses.find(licenseclass => licenseclass.licenseClassCode === code);
                      if (foundlicenseClass) {
                        this.selectedLicenseClasses.push(foundlicenseClass);
                      }
                    });
                  }

                  //Set selectedTags variable to bind tag dropdown
                  if (criteria.tags != null) {
                    const tagIdsArray: number[] = criteria.tags.split(',').map(id => parseInt(id.trim(), 10));

                    this.selectedTags = [];
                    tagIdsArray.forEach(id => {
                      const foundTag = this.tags.find(tag => tag.tagId === id);
                      if (foundTag) {
                        this.selectedTags.push(foundTag);
                      }
                    });
                  }

                  if (criteria != undefined && criteria.appointmentTypes != null) {
                    const appointmentTypeIdsArray: number[] = criteria.appointmentTypes.split(',').map(id => parseInt(id.trim(), 10));

                    this.selectedAppointmentTypeList = [];
                    appointmentTypeIdsArray.forEach(id => {
                      const foundappointmentType = this.appointmentTypeList.find(appointmenttype => appointmenttype.appointmentTypeId === id);
                      if (foundappointmentType) {
                        this.selectedAppointmentTypeList.push(foundappointmentType);
                      }
                    });
                  }

                  this.selectedStatus = criteria.status == '' ? null : criteria.status;

                  this.selectedBranchId = criteria.branchId;

                  this.selectedExpireIn = criteria.expireIn;

                }
              }
            }
          }
          else {
            this.selectedFilter = null;
            if (this.filterCategory == this.filterCategoryProducer) {
              this.branchId = 0;
              this.departmentId = 0;
              this.tenantStatusId = 0;
            } else {

              this.selectedStates = [];
              this.selectedLicenseClasses = [];
              this.selectedTags = [];
              this.selectedAppointmentTypeList = [];
              this.selectedBranchId = null;
              this.selectedStatus = this.status;
              this.selectedExpireIn = null;
              this.selectedFilterName = '';
              this.isDisabled = false;

            }
          }
        }
        else {
          this.selectedFilter = null;
          if (this.filterCategory == this.filterCategoryProducer) {
            this.branchId = 0;
            this.departmentId = 0;
            this.tenantStatusId = 0;
          }
          else {
            this.selectedStates = [];
            this.selectedLicenseClasses = [];
            this.selectedTags = [];
            this.selectedAppointmentTypeList = [];
            this.selectedBranchId = null;
            this.selectedStatus = this.status;
            this.selectedExpireIn = null;
            this.selectedFilterName = '';
            this.isDisabled = false;
          }
        }
      },
      (error) => {
        // Handle error
        console.log(error);
      }
    );
  }

  closeDeletePopup() {
    this.isDeletePopup = false;
  }

  deleteFilter(selectedId) {
    //this.isDeletePopup = true;

    this.filterRequest.FilterId = selectedId;
    this.filterRequest.TenantId = this.tenantId;
    this.filterRequest.UserId = this.userId;

    this.filterService.deleteFilter(this.filterRequest).subscribe(
      (response) => {
        // Handle successful response
        if (response && response.id != null) {
          this.sharedService.showToastMessage("Filter deleted successfully.");
          this.loadFilters("delete", null);
        }
        else {
          this.sharedService.showToastMessage(response.errorMessage);
        }
      },
      (error) => {
        // Handle error        
        if (error && error.message != undefined) {
          this.sharedService.showToastMessage(error.message);
        }
        else {
          this.sharedService.showToastMessage("Oops! Something went wrong. Please try again.");
        }
      }
    );
  }

  closePanel() {
    this.modalService.dismissAll();
    this.applyFilter();
  }

  onSaveFilterChange(currentFilter) {
    if (currentFilter != null && currentFilter != undefined && currentFilter.filterId != null) {
      this.selectedFilter = currentFilter.filterId;
    }

    if (this.selectedFilter != null && this.selectedFilter != undefined) {
      const filterData = this.filterList.find(obj => obj.filterId === this.selectedFilter);

      if (filterData != null && filterData != undefined && filterData.criteria != null) {
        this.selectedFilterName = filterData.name;

        if (filterData.ownerUserId != this.userId) {
          this.isDisabled = true;
        }
        else {
          this.isDisabled = false;
        }

        const criteria = JSON.parse(filterData.criteria);

        if (this.filterCategory == this.filterCategoryProducer) {
          this.tenantStatusId = criteria.tenantStatusId;
          this.branchId = criteria.branchId;
          this.departmentId = criteria.departmentId;
        }
        else {

          //Set selectedStates variable to bind state dropdown
          if (criteria != undefined && criteria.states != null) {
            const stateIdsArray: number[] = criteria.states.split(',').map(id => parseInt(id.trim(), 10));

            this.selectedStates = [];
            stateIdsArray.forEach(id => {
              const foundState = this.states.find(state => state.stateId === id);
              if (foundState) {
                this.selectedStates.push(foundState);
              }
            });
          }

          //Set selectedLicenseClasses variable to bind license class dropdown
          if (criteria != undefined && criteria.licenseClass != null) {
            const licenseClassCodesArray: string[] = criteria.licenseClass.split(',');

            this.selectedLicenseClasses = [];
            licenseClassCodesArray.forEach(code => {
              const foundlicenseClass = this.licenseClasses.find(licenseclass => licenseclass.licenseClassCode === code);
              if (foundlicenseClass) {
                this.selectedLicenseClasses.push(foundlicenseClass);
              }
            });
          }

          //Set selectedTags variable to bind tags dropdown
          if (criteria != undefined && criteria.tags != null) {
            const tagIdsArray: number[] = criteria.tags.split(',').map(id => parseInt(id.trim(), 10));

            this.selectedTags = [];
            tagIdsArray.forEach(id => {
              const foundTag = this.tags.find(tag => tag.tagId === id);
              if (foundTag) {
                this.selectedTags.push(foundTag);
              }
            });
          }

          //Set selectedAppointmentTypeList variable to bind LOA dropdown
          if (criteria != undefined && criteria.appointmentTypes != null) {
            const appointmentTypeIdsArray: number[] = criteria.appointmentTypes.split(',').map(id => parseInt(id.trim(), 10));

            this.selectedAppointmentTypeList = [];
            appointmentTypeIdsArray.forEach(id => {
              const foundAppointmentTypeList = this.appointmentTypeList.find(appointmenttype => appointmenttype.appointmentTypeId === id);
              if (foundAppointmentTypeList) {
                this.selectedAppointmentTypeList.push(foundAppointmentTypeList);
              }
            });
          }
          // if(criteria != undefined && criteria.status != null && criteria.status != 0)
          // {
          this.selectedStatus = criteria.status == '' ? null : criteria.status;
          // }

          // if(criteria != undefined && criteria.branchId != null && criteria.branchId != 0)
          // {
          this.selectedBranchId = criteria.branchId;
          // }

          // if(criteria != undefined && criteria.expireIn != null && criteria.expireIn != 0)
          // {
          this.selectedExpireIn = criteria.expireIn;
          // }
        }
      }
    }
  }

  @HostListener("click", ["$event"]) onClickDelete(event: any) {
    if (event.target.classList.contains('delete-item')) {
      event.preventDefault();
      this.deleteFilter(event.target.classList[0])
    }

  }

  scrolltoBottom() {
    document.getElementById('scrollTo')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}

function arraysEqual<T>(a: T[], b: T[]): boolean {
  return a.length === b.length && a.every((value, index) => value === b[index]);
}
