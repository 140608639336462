export interface Filter {
  FilterId: number;
  TenantId: number;
  OwnerUserId: number;
  FilterCategoryId: number;
  Name: string;
  Default: boolean;
  Public: boolean;
  Criteria: string;
  CreatedDate: Date;
  CreatedBy: number;
  UpdatedDate: Date;
  UpdatedBy: number;
}

export interface FilterList {
  filterId: number;
  tenantId: number;
  ownerUserId: number;
  filterCategoryId: number;
  name: string;
  default: boolean;
  public: boolean;
  criteria: string;
  createdDate: Date;
  createdBy: number;
  updatedDate: Date;
  updatedBy: number;
}

export interface FilterRequest {
  FilterId: number;
  TenantId: number;
  UserId: number;
}

export interface FilterResponse {
  id: number;
  errorMessage: string;
}

export enum FilterCategory {
  Producers = 1,
  Licenses = 2,
  Appointments = 3,
  IndividualBulkLicenseRenewal = 4,
  Task = 5
}

export enum FilterTypeList {
  Status = 1,
  Priority = 2,
  TaskType = 3,
  Reason = 4,
  AssignBy = 5,
  DueDate = 6,
  CreatedBy = 7,
  Branch = 8,
  Department = 9
}

